import dataLists from './dataPrivacyLists';
import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';
import styles from 'templates/data-privacy/dataPrivacy.module.scss';
import React, { Fragment } from 'react';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import { Link } from '@moonshineragency/ui/src/components/Link/Link';

const DataPrivacy = () => {
  const encodedEmail = (
    <Link
      asType="external"
      target="_blank"
      variant="noUnderline"
      className={styles.link}
      href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#97;&#114;&#111;&#108;&#121;&#110;&#64;&#115;&#99;&#111;&#112;&#101;&#45;&#115;&#99;&#105;&#46;&#111;&#114;&#103;"
    >
      &#99;&#97;&#114;&#111;&#108;&#121;&#110;&#64;&#115;&#99;&#111;&#112;&#101;&#45;&#115;&#99;&#105;&#46;&#111;&#114;&#103;
    </Link>
  );
  const textWithCtGovLink = (
    <Content size="copy100" variant="light">
      We process your information for purposes based on legitimate interests,
      the fulfillment of your wish to either sign up for notifications on
      new/updated trials or to get in contact with a contact person of the study
      (as listed on{' '}
      <Link
        target="_blank"
        variant="noUnderline"
        href="https://clinicaltrials.gov/"
        asType="external"
        className={styles.link}
      >
        clinicaltrials.gov
      </Link>
      ).
    </Content>
  );
  const listArray = [
    'Trial and trial location',
    'Age',
    'Gender',
    'Injury Level (from – to)',
    'Severity',
    'Time since Injury',
    'Email address',
    'Phone number',
  ];
  const collectList = (
    <Content as="div" size="copy100" variant="light" noSpacing>
      <ul className={styles.list}>
        {listArray.slice(0, 6).map(list => (
          <li>{list}</li>
        ))}
      </ul>
      <Content size="copy100" variant="light">
        The goal of analytics in SCI Trials Finder is solely to help improve our
        service and the work of SCI scientists all around the world.
      </Content>
    </Content>
  );
  const shareList = (
    <Content as="div" size="copy100" variant="light" noSpacing>
      <ul className={styles.list}>
        {listArray.map(list => (
          <li>{list}</li>
        ))}
      </ul>
      <Content size="copy100" variant="light">
        In this list only email-address is a mandatory information.
      </Content>
    </Content>
  );

  // TODO: update link adress
  const textWithGdprLink = (
    <Content size="copy100" variant="light">
      For this, our site uses Web and Mobile Analytics by{' '}
      <Link
        target="_blank"
        variant="noUnderline"
        href="https://clinicaltrials.gov/"
        asType="external"
        className={styles.link}
      >
        Matomo
      </Link>
      . Matomo works without cookies,{' '}
      <Link
        target="_blank"
        variant="noUnderline"
        href="https://clinicaltrials.gov/"
        asType="external"
        className={styles.link}
      >
        ensures GDPR compliance{' '}
      </Link>
      and stores data within the European Union.
    </Content>
  );

  return (
    <Layout
      title="Data Privacy, spinal cord injury trials"
      description="Find out which measures SCI Trials Finder takes to protects your privacy."
      onlyShowSearchIcon
      isContentpage
    >
      <Container size="lg">
        <div className={styles.dataContainer}>
          <Heading size="h1" theme="primary">
            Data privacy statement
          </Heading>
          <div className={styles.headingText}>
            <Content size="copy75" variant="bold">
              Last updated 9/1/2021
            </Content>
            <Content
              size="copy100"
              variant="light"
              className={styles.mainSection}
            >
              We are committed to protecting your personal information and your
              right to privacy. If you have any questions or concerns about our
              policy, or our practices with regards to your personal
              information, please contact us at {encodedEmail}.
            </Content>
            <Content
              size="copy100"
              variant="light"
              className={styles.mainSection}
            >
              In the following policy we explain to you what information we
              collect, how we use it and what rights you have. If there are any
              terms in this privacy policy that you do not agree with, please
              discontinue use of our Site and our services.
            </Content>
          </div>
          {dataLists.map(list => {
            return (
              <Fragment key={list.title}>
                <div>
                  <div id={list.linkId} />
                  <Heading
                    theme="primary"
                    className={styles.dataListHeading}
                    size="h3"
                  >
                    {list.title.toUpperCase()}
                  </Heading>
                  {list.textArray.map(el => {
                    return (
                      <div key={el.id}>
                        <Content
                          size="copy100"
                          variant="light"
                          className={styles.mainSection}
                        >
                          {el.section}
                          {el.sectionWithGdprLink === '' && textWithGdprLink}
                          {el.sectionWithCtGovLink === '' && textWithCtGovLink}
                        </Content>
                        {el.collectList === '' && collectList}
                        {el.shareList === '' && shareList}
                      </div>
                    );
                  })}
                </div>
              </Fragment>
            );
          })}

          <div className={styles.contactWrapper}>
            <Content size="copy100" variant="light">
              If you have questions or comments about this policy, you may
              contact us by email at {encodedEmail}.
            </Content>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default DataPrivacy;
